import React, { useState } from "react";

import "./styles.scss";
import GreenButton from "@Common/GreenButton";
import Loader from "@Common/Loader";
import { getRedirectToServiceLinkByParams } from "@services/api";

import desembolsos from "@/assets/images/desembolsos.jpg";
import discount from "@/assets/images/discount.svg";
import calendar from "@/assets/images/calendar.svg";

import { useAuthState } from "@/contexts/AuthContext";

import { firebaseConfig } from "@config/firebaseConfig";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import AdCarousel from '@/components/AdCarousel';

const CalculatorBlock = ({ text, image }) => {
  return (
    <div className="calculator-blocks-item">
      <img alt="info" src={image} />
      <p>{text}</p>
    </div>
  );
};

const CalculatorSection = React.forwardRef((props, ref) => {
  const { isLoading } = useAuthState();
  const [isFetching, setIsFetching] = useState(false);

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const logEventFirebase = async (e) => {
    e.preventDefault();
    logEvent(analytics, "Click solicita tu credito GA4 Event");
  };

  const redirectToServicePage = async (e) => {
    e.preventDefault();

    setIsFetching(true);

    try {
      const { link } = await getRedirectToServiceLinkByParams({
        Amount: 200,
        Term: 30,
      });

      window.location.assign(link);

      if (!link) {
        setIsFetching(false);
      }
    } catch (e) {
      setIsFetching(false);
      console.error(e);
    }
  };

  const sendLoanAmountEvent = () => {
    if (typeof AF === 'function') {
      // eslint-disable-next-line no-undef
      AF('pba', 'event', {
        eventType: 'EVENT',
        eventValue: { loanAmount: 200 },
        eventName: 'Loan_amount_chosen'
      });
      console.log('Событие отправлено: Loan_amount_chosen');
    } else {
      console.error('AppsFlyer SDK не загружен');
    }
  };

  return (
    <section ref={ref} className="container-width-100 ">
      <div className="block-1 container-page" id="block-1">
        <div className="bg-man"></div>
        <div className="calculator-slider">
          <>
            <h2>Préstamos en linea</h2>

            <img
              src={desembolsos}
              alt="desembolsos"
              style={{ width: "100%" }}
              className="desembolsos"
            />

            <div className="calculator">
              <div className="calculator__button body_m-extrabold">
                {isFetching || isLoading ? (
                  <div className="calculator__info-loader">
                    <Loader />
                  </div>
                ) : (
                  <GreenButton
                    disabled={isFetching}
                    onClick={(e) => {
                      logEventFirebase(e);
                      redirectToServicePage(e);
                      sendLoanAmountEvent();
                    }}
                  >
                    Solicita tu Crédito
                  </GreenButton>
                )}
              </div>
            </div>

            <AdCarousel />

            <div className="calculator-blocks">
              <CalculatorBlock
                image={discount}
                text="Tasa anual desde 225% hasta 438%"
              />
              <CalculatorBlock
                image={calendar}
                text="Plazo de préstamo de 62 días a 180 días con posibilidad de reembolso anticipado"
              />
            </div>
          </>
        </div>
        <div className="error-message" id="calc-error">
          <p></p>
        </div>
        <div className="breakpoint-1" id="block-3"></div>
      </div>
    </section>
  );
});

export default CalculatorSection;
